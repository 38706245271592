@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.item-text1, .item-text2, .item-text3, .item-text4, .margin-list, .search, .btn-modal {
  font-family: Nunito Sans;
}

.title-1, .location {
    font-family: Nunito Sans;
}

ion-label {
    --ion-font-family: Nunito Sans;
}

img{
    width:100%;
    height:100%;
}
/* body{
    --ion-background-color: #f1f4f7;
} */
ion-col {
    --ion-background-color: #fff !important;
}

.myCard{
  position:relative;
  border-radius: 15px;
}

.myOverlay{
    width: 100%;
    height: 70px;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    opacity: 0.8;
    background: rgb(126, 124, 124);
    color: #fff;
    padding: 20px 5px;
}

.ion-content {
  height: 180px;
  border-bottom-left-radius: 50% 80px;
  border-bottom-right-radius: 50% 80px;
}

/* .gray-box {
  height: 300px;
  background-color: #E5E5E5;
} */

.list {
    margin: 0px 4px;
    border-radius: 10px 10px 0px 0px;
}

.green-box {

  background-color: #009688;
  border-bottom-left-radius: 50% 80px;
  border-bottom-right-radius: 50% 80px;
}

.green-box.location {
    height: 195px;
}

.margin-list {
  margin-top: 50px !important;
}
.title{
    margin-top: 4px;
  }
  .titles {
      margin-bottom: 4px;
  }
  

.title-1 {
  color: #fff;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  margin-left: 15px;
  font-weight: bold;
}

/* .title-2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0px;
  margin-left: 25px;
  text-align: left;  
  color: #fff;
} */

.btn-ganti {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
    border-color: #fff !important;
    /* White Button Border Color*/
    --border-color: ('#fff');
    --border-radius: 0;
    width: 62px !important;
    height: 25px !important;
    border-radius: 0px 0px !important;
    text-transform: none;
}

.search-icon {
    fill: #fff;
    padding-top: 4px;
}

.ion-margin {
    margin: 25px 0 5px 0 !important
}

.gray-border {
    border-top:solid 1px rgb(212, 210, 210);
    border-bottom:solid 1px rgb(212, 210, 210);
    padding: 15px 0;
}

.gray-border-bottom {
    border-bottom:solid 1px rgb(212, 210, 210);
    padding-bottom: 15px;
}

.item {
    border-bottom:solid 1px rgb(212, 210, 210);
    padding: 0px 0px !important;
}

.item-text1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 2px;
}

.item-text2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: #00786D !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 2px;
}

.item-text3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #99A4AB !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 2px;
}

.card-title {
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-thumbnail {
    width: 20%;
    height: 20%;
}

.location-thumbnail {
    width: 8%;
    height: 20%;
}

.dki-margin {
    margin-left: 12px;
}

.gray-color {
    color: #99A4AB !important;
}

.black-color {
    color: #000;
}

.my-custom-class {
    position: absolute;
    height: 400px !important;
    top: calc(100% - (400px));
}

.location-custom-modal {
    position: absolute;
    height: 400px !important;
    top: calc(100% - (400px));
}

.home-search-modal {
    padding: 0;
    margin: 0;
    position: absolute;
    height: 80px !important;
    top: (calc(100% - (80px)));
}

.display-block { 
    display: block !important;
    text-align: center;
    margin: 10px auto;
}

.margin-5 {
    margin: 10px 2px 10px 2px;
}

.btn-modal {
    --background: #009688 !important;
    padding: 2px 20px;
    margin-top: 15px;
}

ion-searchbar ion-icon {
    right: 16px !important;
    left: auto !important;
}


/* .searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
    padding-left: unset;
    -webkit-padding-start: 30px;
    padding-inline-start: 30px;
    background: white !important;
} */
.location-style {
    width: 25px;
}

.item-location-text1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item-margin {
    margin: 0px 0px;
    padding: 0px 0px;
}

.ion-location-margin {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.border-radius-10 {
    background-color: #fff;
    
    position: relative;
    top:0px;
    /* margin:0px 3px; */
    border-radius: 10px 10px 0px 0px;
 }

#myUL {
    margin: 0px 3px 0px 2px;
}

.no-spacing {
    position: relative;
    top: 5px;
}

/* .ion-color.sc-ion-searchbar-md-h .searchbar-input.sc-ion-searchbar-md {
    background: var(--ion-color-base);
    color: black;
}

.searchbar-input-container.sc-ion-searchbar-md {
    display: block;
    position: relative;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    width: 100%;
    color: black;
} */
.bar {
    margin: 7px;
   
}
.location
{
    background-color:#fff;
    padding: 0;
    color: #99A4AB;
    border: 1px solid white;
    border-radius: 4px;
    
}
.ion-color.sc-ion-searchbar-md-h {
    color: #99A4AB;
}

.container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .checkloc
  {
    position: absolute;
    /* left: 250px; */
    /* top: 0px; */
    /* color: #009688; */
    /* right: -166px; */
    right: 5px;
    top: 0px; 

  }

  .margin-list {
    position: relative;
    top: -7px;
  
  }

  .scrolling {
    display: flex;
    justify-content: space-between;
    --overflow: hidden !important;
    
  }