@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito Sans);
.item-text1, .item-text2, .item-text3, .item-text4, .margin-list, .search, .btn-modal {
  font-family: Nunito Sans;
}

.title-1, .location {
    font-family: Nunito Sans;
}

ion-label {
    --ion-font-family: Nunito Sans;
}

img{
    width:100%;
    height:100%;
}
/* body{
    --ion-background-color: #f1f4f7;
} */
ion-col {
    --ion-background-color: #fff !important;
}

.myCard{
  position:relative;
  border-radius: 15px;
}

.myOverlay{
    width: 100%;
    height: 70px;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    opacity: 0.8;
    background: rgb(126, 124, 124);
    color: #fff;
    padding: 20px 5px;
}

.ion-content {
  height: 180px;
  border-bottom-left-radius: 50% 80px;
  border-bottom-right-radius: 50% 80px;
}

/* .gray-box {
  height: 300px;
  background-color: #E5E5E5;
} */

.list {
    margin: 0px 4px;
    border-radius: 10px 10px 0px 0px;
}

.green-box {

  background-color: #009688;
  border-bottom-left-radius: 50% 80px;
  border-bottom-right-radius: 50% 80px;
}

.green-box.location {
    height: 195px;
}

.margin-list {
  margin-top: 50px !important;
}
.title{
    margin-top: 4px;
  }
  .titles {
      margin-bottom: 4px;
  }
  

.title-1 {
  color: #fff;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  margin-left: 15px;
  font-weight: bold;
}

/* .title-2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0px;
  margin-left: 25px;
  text-align: left;  
  color: #fff;
} */

.btn-ganti {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
    border-color: #fff !important;
    /* White Button Border Color*/
    --border-color: ('#fff');
    --border-radius: 0;
    width: 62px !important;
    height: 25px !important;
    border-radius: 0px 0px !important;
    text-transform: none;
}

.search-icon {
    fill: #fff;
    padding-top: 4px;
}

.ion-margin {
    margin: 25px 0 5px 0 !important
}

.gray-border {
    border-top:solid 1px rgb(212, 210, 210);
    border-bottom:solid 1px rgb(212, 210, 210);
    padding: 15px 0;
}

.gray-border-bottom {
    border-bottom:solid 1px rgb(212, 210, 210);
    padding-bottom: 15px;
}

.item {
    border-bottom:solid 1px rgb(212, 210, 210);
    padding: 0px 0px !important;
}

.item-text1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 2px;
}

.item-text2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: #00786D !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 2px;
}

.item-text3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #99A4AB !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 2px;
}

.card-title {
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-thumbnail {
    width: 20%;
    height: 20%;
}

.location-thumbnail {
    width: 8%;
    height: 20%;
}

.dki-margin {
    margin-left: 12px;
}

.gray-color {
    color: #99A4AB !important;
}

.black-color {
    color: #000;
}

.my-custom-class {
    position: absolute;
    height: 400px !important;
    top: calc(100% - (400px));
}

.location-custom-modal {
    position: absolute;
    height: 400px !important;
    top: calc(100% - (400px));
}

.home-search-modal {
    padding: 0;
    margin: 0;
    position: absolute;
    height: 80px !important;
    top: (calc(100% - (80px)));
}

.display-block { 
    display: block !important;
    text-align: center;
    margin: 10px auto;
}

.margin-5 {
    margin: 10px 2px 10px 2px;
}

.btn-modal {
    --background: #009688 !important;
    padding: 2px 20px;
    margin-top: 15px;
}

ion-searchbar ion-icon {
    right: 16px !important;
    left: auto !important;
}


/* .searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
    padding-left: unset;
    -webkit-padding-start: 30px;
    padding-inline-start: 30px;
    background: white !important;
} */
.location-style {
    width: 25px;
}

.item-location-text1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item-margin {
    margin: 0px 0px;
    padding: 0px 0px;
}

.ion-location-margin {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.border-radius-10 {
    background-color: #fff;
    
    position: relative;
    top:0px;
    /* margin:0px 3px; */
    border-radius: 10px 10px 0px 0px;
 }

#myUL {
    margin: 0px 3px 0px 2px;
}

.no-spacing {
    position: relative;
    top: 5px;
}

/* .ion-color.sc-ion-searchbar-md-h .searchbar-input.sc-ion-searchbar-md {
    background: var(--ion-color-base);
    color: black;
}

.searchbar-input-container.sc-ion-searchbar-md {
    display: block;
    position: relative;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    width: 100%;
    color: black;
} */
.bar {
    margin: 7px;
   
}
.location
{
    background-color:#fff;
    padding: 0;
    color: #99A4AB;
    border: 1px solid white;
    border-radius: 4px;
    
}
.ion-color.sc-ion-searchbar-md-h {
    color: #99A4AB;
}

.container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .checkloc
  {
    position: absolute;
    /* left: 250px; */
    /* top: 0px; */
    /* color: #009688; */
    /* right: -166px; */
    right: 5px;
    top: 0px; 

  }

  .margin-list {
    position: relative;
    top: -7px;
  
  }

  .scrolling {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    --overflow: hidden !important;
    
  }
.my-custom-class {
    --spinner-color: #2f9688;
    /* height: 100%; */
  }

  .loader-style {
    height: 100vh !important;
    --spinner-color: #2f9688;

  }


.item-text1, .item-text2, .item-text3, .item-text4, .margin-list, .search, .btn-modal {
  font-family: Nunito Sans;
}

.ion-page {
  font-family: Nunito Sans;
  max-width: 600px;
  margin: 0 auto;
}

.list-items {
  font-family: Nunito Sans;
  padding-left: 0px;
}

.reset-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 14px;
  font-size: large;
}

.no-img {
  height: 70px;
  width: 70px;
  text-align: center;
  letter-spacing: 1px;
}

.search {
  color: #99A4AB;
  text-align: left;
  padding: 0;
  position: absolute;
  left: 3%;
  width: 94vw;
  height: 45px;
  border-radius: 6px;
  background-color: #fff;
  padding-right: 10px;
}

.modal-no-img {
  height: 70px;
  width: 70px;
  letter-spacing: 1px;
  background-color: #009688;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-top: 25px;
  margin-bottom: 10px !important;
  border-radius: 5px;
  display: table;
  margin: auto;
}

.hidden { 
  display:none; 
}

.txt-uppercase {
  text-transform: uppercase;
  overflow: hidden;
  line-height: 25px;
}

.city-align {
    text-align: left !important;
    padding-left: 20px !important;
  }
  
  .myCard{
    position:relative;
    border-radius: 15px;
    height: 140px;
    width: 100px; 
    margin: 5px;
  }
  
  .myOverlay{
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 54px;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    opacity: 1 !important;
    color: #fff;
    font-weight: bold !important;
    padding: 0px 5px;
  }

  
  

  .myOverlay1{
    width: 100%;
    height: 72px;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.3);
    color:#fff;
    font-weight: bold;
    padding: 0px 5px;
  }

  .ion-content {
    height: 180px;
    border-bottom-left-radius: 50% 80px;
    border-bottom-right-radius: 50% 80px;
  }

  .gray-box {
    height: 300px;
    background-color: #E5E5E5;
  }

  .green-box {
    height: 240px;
    background-color: #009688;
    border-bottom-left-radius: 50% 80px;
    border-bottom-right-radius: 50% 80px;
  }
  

  .margin-list {
    margin-top: 10px !important;
  }

  .title-2 {
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    margin-left: 25px;
    text-align: center;  
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
  }

  /* .title-2 {
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    margin-left: 0px;
    text-align: left;  
    color: #fff;
    display: inline-block;
  } */

  .btn-ganti {
    font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  border-color: #fff !important;
  /* White Button Border Color*/
  --border-color: ('#fff');
  --border-radius: 0;
  width: 62px !important;
  height: 25px !important;
  border-radius: 0px 0px !important;
  text-transform: none;
  }

.search-icon {
  fill: #fff;
  padding-top: 4px;
  /* position: relative;
    top: 4px;
    right: -4px; */
}

.ion-margin {
  margin: 25px 0 5px 0 !important
}

.gray-border {
  font-family: Nunito Sans;
  border-top:solid 1px rgb(212, 210, 210);
  border-bottom:solid 1px rgb(212, 210, 210);
  padding: 10px 0;
  margin-right: 3px;
}

.gray-border-bottom {
  border-bottom:solid 1px rgb(212, 210, 210);
  padding-bottom: 15px;
}

.item {
  border-bottom:solid 1px rgb(212, 210, 210);
  padding: 15px 0px;
}

.item-text1 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 4px 2px;
}

.item-text2 {
font-size: 14px;
font-weight: 500;
line-height: 19px;
color: #00786D !important;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
margin: 4px 2px;
}

.item-text3 {
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
color: #99A4AB !important;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
margin: 4px 2px;
}
  .card-title {
    font-family: Nunito Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: unset;
    position: absolute;
    font-weight: bold;
    top:5px;
  }
  

.list-thumbnail.home{
  /* width: 20%;
  height: 20%; */
  height: 70px !important;
  width: 70px !important;
  background-color: #009688;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-top: 25px;
  margin-bottom: 10px !important;
  border-radius: 5px;
}

.dki-margin {
  margin-left: 12px;
}

.gray-color {
  color: #99A4AB !important;
}

.black-color {
  color: #000;
}

.my-custom-class {
  position: absolute;
  border-radius: 16px 16px 0px 0px;
  height: 400px !important;
  top: calc(100% - (400px));
  overflow: hidden !important;
}

.display-block { 
  display: block !important;
  text-align: center;
  margin: 10px auto;
}

.margin-5 {
  margin: 10px 2px 10px 2px;
}

.btn-modal {
  --background: #009688 !important;
  padding: 2px 20px;
  margin-top: 15px;
}

ion-searchbar ion-icon {
  right: -46px !important;
  left: auto !important;
}

.test
{
  position: absolute;
    right: 14px;
    z-index: 1;
    background-color: #009688;
    height: 32px;
    color: white;
    border-radius: 6px;

}

.button-native {
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: var(--padding-start);
          padding-inline-start: var(--padding-start);
  -webkit-padding-end: var(--padding-end);
          padding-inline-end: var(--padding-end);
  border: -11px;
  border-radius: 4px !important;
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

.new-background-color{
  --background: #009688;;
}

.cust-01-page{
  background: rgba(0, 0, 0, 0.67);
  display: none;
  z-index: 9 !important;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

ion-slides {
  height: 100%;
}

.bg-1 {
  background-size: cover;
}
.bg-2 {
  background-size: cover;
}
.bg-3 {
  background-size: cover;
}

.category-name {
   position: absolute;
     width: 100%;
     text-align: center;
     min-height: 35px;
      bottom: 0px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      background: rgba(1, 28, 44, 0.5);
      border-radius: 0px 0px 4px 4px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      padding-left: 1rem;
  
}

.popup {
  --overflow: hidden !important;
}


.home-search-modal {
  margin: 10px;
  border-radius: 6px;
}
.ionlabel {
  width: 100%;
  text-align: left;
}

.button1 {
  position: fixed;
    bottom: 7%;
    width: 100%;
}
.carousal
{
  display:-webkit-flex;
  display:flex
}
.scrollmenu
{
  display: -webkit-flex;
  display: flex;
    overflow-x: scroll!important;
    overflow-y: hidden;
}
.myCard {
  --ion-display:flex;
  --ion-overflow-x:scroll;
}

@media only screen and (min-width: 415px)and (max-width: 600px) {
  .city-location {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:center;
            justify-content:center;
    margin-left: 25px;
  }
  .list-items {
    font-family: Nunito Sans;
    padding-left: 3px;
  }
  .gray-border {
    margin-left: 7px;
  }
   .searchbarlist {
    margin: 12px;
  }
  /* .search {
    width: 96vw;
  } */
  .scrollmenu
  {
    margin-left: 12px;
  }
}

@media only screen and (min-width: 768px)and (max-width: 1024px) {
  .my-custom-class {
    position: absolute;
    --border-radius: 16px 16px 0px 0px;
    --width: 100%;
    --height: 400px !important;
    top: calc(100% - (400px));
  }
  /* .title-2 {
    margin-left: 17px;
  } */
  .home-search-modal{
    --ion-background-color:#09312d;
    --height:90px;
    --width:100%;
    --border-radius: 10px;
    opacity: 1;
  }
  .green-box {
    border-bottom-left-radius: 70% 80px;
    border-bottom-right-radius:70% 80px;
  }
  .city-location {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 3px 0px;
  }
  .gray-border {
    margin-left: 30px;
  }
  .searchbarlist {
    margin: 19px;
  }
  .search {
    width: 94%;
  }
  .scrollmenu
  {
    margin: 20px 0px 10px 30px ;
      overflow-x: hidden !important;
  }
  .product-list {
    width:70px;
    height:70px;
  }
  .list-items {
    padding-left: 13px;
  }
  .gray-border {
    padding-left: 14px;
  }
}
.not-found {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  color: #011C2C;
  text-align: center;
  margin: 0px 83px;
  }

.loadMore {
  text-align: center;
}

.reset-button {
  font-family: Nunito Sans;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  line-height: 19px;
  color: #03AD87;
  position: absolute;
  right: 16px;
  padding-top: 4px;
}
.reset-padding {
  padding-right: 50px !important;
}
.searchbar-input.sc-ion-searchbar-ios {
  background-color: #fff;
}

.txt-uppercase {
  text-transform: uppercase;
  overflow: hidden;
  line-height: 25px;
}

.city-align {
  text-align: left !important;
  padding-left: 20px !important;
}

img{
    width:100%;
    height:100%;
}

#myCard{
  position:relative;
  border-radius: 15px;
  height: 140px;
  width: 100px; 
  margin: 3px;
}

#myCard1{
  position:relative;
  border-radius: 15px;
  height: 140px;
  width: 100px; 
  margin: 3px;
 
}

#myCard2{
  position:relative;
  border-radius: 15px;
  height: 140px;
  width: 100px; 
  margin: 3px; 
}

.title-1 {
  font-family: Nunito Sans;
  color: #fff;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  margin-left: 13px;
  font-weight: bold;
  display: block;
}

.btn-ganti {
  font-family: Nunito Sans;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 16px;
letter-spacing: 0px;
text-align: center;
color: #fff;
border-color: #fff !important;
--border-color: ('#fff');
--border-radius: 0;
width: 62px !important;
height: 25px !important;
border-radius: 0px 0px !important;
text-transform: none;
}

.search-icon {
fill: #fff;
padding-top: 4px;
}

.ion-margin {
margin: 25px 0 5px 0 !important
}

.gray-border {
font-family: Nunito Sans;
border-top:solid 1px rgb(212, 210, 210);
border-bottom:solid 1px rgb(212, 210, 210);
padding: 10px 0;
margin-right: 3px;
}

.gray-border-bottom {
border-bottom:solid 1px rgb(212, 210, 210);
padding-bottom: 8px;
}

.item {
border-bottom:solid 1px rgb(212, 210, 210);
padding: 15px 0px;
}

.item-text1 {
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
white-space: break-spaces;
text-overflow: ellipsis;
margin: 4px 2px;
font-family: Roboto;

/* font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
color: #99A4AB !important;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
margin: 4px 2px; */
}

.item-text4 {
  font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
white-space: break-spaces;
text-overflow: ellipsis;
margin: 4px 2px;
font-family: Roboto;
color: #99A4AB !important;
}

.item-text2 {
font-size: 14px;
font-weight: 500;
line-height: 19px;
color: #00786D !important;
white-space: break-spaces;
overflow: hidden;
text-overflow: ellipsis;
margin: 4px 2px;
}

.item-text3 {
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
color: #99A4AB !important;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
margin: 4px 2px;
}

.card-title {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: unset;
  position: absolute;
  font-weight: bold;
  top:5px;
}

.list-thumbnail {
height: 70px !important;
width: 70px !important;
}

.dki-margin {
margin-left: 12px;
}

.gray-color {
color: #99A4AB !important;
}

.black-color {
color: #000;
}

.my-custom-class {
  position: absolute;
  border-radius: 16px 16px 0px 0px;
  height: 400px !important;
  top: calc(100% - (400px));
  overflow: hidden !important;
}

.display-block { 
display: block !important;
text-align: center;
padding: 7px;
}

.margin-5 {
margin: 10px 2px 10px 2px;
}

.btn-modal {
--background: #009688 !important;
padding: 2px 20px;
margin-top: 15px;
}

ion-searchbar ion-icon {
right: -46px !important;
left: auto !important;
}
.button-native {
padding-left: unset;
padding-right: unset;
-webkit-padding-start: var(--padding-start);
        padding-inline-start: var(--padding-start);
-webkit-padding-end: var(--padding-end);
        padding-inline-end: var(--padding-end);
border: -11px;
border-radius: 4px !important;
}

.test {
 position: absolute;
  right: 0px;
  z-index: 1;
  background-color: #009688;
  height: 32px;
  color: white;
  border-radius: 6px;

}
.popup {
  --overflow: hidden !important;
  padding: 0;
  margin: 0;
}
.home-search-modal {
  --ion-background-color:#09312d;
}
ion-searchbar {
  --ion-background-color:white;
}
.home-search-modal {
  margin: 0px;
}

.mdlcls1
{
position: relative;
  top: 13px;
  right: -17px;
  font-size: 24px;
  height: 30px;
  margin-bottom: 10px;
}

.searchbar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;

}
.searchbarlist {
  margin-top: 14px;
  margin-right: 10px;
}
.mdlcls {
    position: relative;
      top: 22px;
      right: -5px;
      font-size: 23px;
      color: white;  
}

.search {
  color: #99A4AB;
  text-align: left;
  padding: 0;
  position: absolute;
  left: 3%;
  width: 94vw;
  height: 45px;
  border-radius: 6px;
  background-color: #fff;
  padding-right: 10px;
}

#overlay {
position: fixed;
display: none;
width: 100%;
height: 100%;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0,0,0,0.5);
z-index: 2;
cursor: pointer;
}

.new-background-color{
--background: #009688;;
}

#nvgbutton {
text-transform: capitalize;
height: 58px;
position: relative;
top: 20px;
font-weight: bold !important;
}

.scroll-y {
  touch-action: pan-y;
  overscroll-behavior-y: contain;
}

/* .mdlcls1
{
position: relative;
  top: 13px;
  right: -17px;
  font-size: 24px;
  height: 30px;
} */

.scrollmenu
{
  display: -webkit-flex;
  display: flex;
    overflow-x: scroll!important;
    overflow-y: hidden;
}

.active-card{
  position: relative;
  border: 3px solid #F7B500;
  visibility: visible;
}

.active-card .close-icon-btn{
  font-size: 12px;
}

.card-close-btn-outer{
  display: none;
}

.active-card .card-close-btn-outer{
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #F7B500;
  color: white;
  border-radius: 50%;
  padding: 3px;
  right: 7px;
  top: 7px;
  z-index: 998;
}
@media only screen and (min-width: 1025px)and (max-width: 2024px) {
  .my-custom-class {
    position: absolute;
    --border-radius: 16px 16px 0px 0px;
    --width: 100%;
    --height: 400px !important;
    top: calc(100% - (400px));
  }
  .home-search-modal {
    --height:90px;
    --width:100%;
    --border-radius: 10px;
  }
  .green-box {
    border-bottom-left-radius: 70% 80px;
    border-bottom-right-radius:70% 80px;
  }
  .city-location {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 3px 0px;
  }
  
  .searchbarlist {
    margin: 19px;
  }
  .search {
    width: 94%;
  }
  .scrollmenu
  {
    margin: 20px 0px 10px 18px ;
      overflow-x: hidden !important;
  }
  .product-list {
    width:70px;
    height:70px;
  }
  .list-items {
    padding-left: 13px;
  }

}




/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #fff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #009688;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}



/* @media (prefers-color-scheme: dark) { */
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body[color-theme="dark"] {
    --ion-color-primary: #fff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body[color-theme="dark"] {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal[color-theme="dark"] {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body[color-theme="dark"] {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  
}
