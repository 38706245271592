@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.item-text1, .item-text2, .item-text3, .item-text4, .margin-list, .search, .btn-modal {
  font-family: Nunito Sans;
}

.ion-page {
  font-family: Nunito Sans;
  max-width: 600px;
  margin: 0 auto;
}

.list-items {
  font-family: Nunito Sans;
  padding-left: 0px;
}

.reset-list {
  display: flex;
  justify-content: space-between;
  padding-left: 14px;
  font-size: large;
}

.no-img {
  height: 70px;
  width: 70px;
  text-align: center;
  letter-spacing: 1px;
}

.search {
  color: #99A4AB;
  text-align: left;
  padding: 0;
  position: absolute;
  left: 3%;
  width: 94vw;
  height: 45px;
  border-radius: 6px;
  background-color: #fff;
  padding-right: 10px;
}

.modal-no-img {
  height: 70px;
  width: 70px;
  letter-spacing: 1px;
  background-color: #009688;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-top: 25px;
  margin-bottom: 10px !important;
  border-radius: 5px;
  display: table;
  margin: auto;
}

.hidden { 
  display:none; 
}

.txt-uppercase {
  text-transform: uppercase;
  overflow: hidden;
  line-height: 25px;
}

.city-align {
    text-align: left !important;
    padding-left: 20px !important;
  }
  
  .myCard{
    position:relative;
    border-radius: 15px;
    height: 140px;
    width: 100px; 
    margin: 5px;
  }
  
  .myOverlay{
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 54px;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    opacity: 1 !important;
    color: #fff;
    font-weight: bold !important;
    padding: 0px 5px;
  }

  
  

  .myOverlay1{
    width: 100%;
    height: 72px;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.3);
    color:#fff;
    font-weight: bold;
    padding: 0px 5px;
  }

  .ion-content {
    height: 180px;
    border-bottom-left-radius: 50% 80px;
    border-bottom-right-radius: 50% 80px;
  }

  .gray-box {
    height: 300px;
    background-color: #E5E5E5;
  }

  .green-box {
    height: 240px;
    background-color: #009688;
    border-bottom-left-radius: 50% 80px;
    border-bottom-right-radius: 50% 80px;
  }
  

  .margin-list {
    margin-top: 10px !important;
  }

  .title-2 {
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    margin-left: 25px;
    text-align: center;  
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
  }

  /* .title-2 {
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    margin-left: 0px;
    text-align: left;  
    color: #fff;
    display: inline-block;
  } */

  .btn-ganti {
    font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  border-color: #fff !important;
  /* White Button Border Color*/
  --border-color: ('#fff');
  --border-radius: 0;
  width: 62px !important;
  height: 25px !important;
  border-radius: 0px 0px !important;
  text-transform: none;
  }

.search-icon {
  fill: #fff;
  padding-top: 4px;
  /* position: relative;
    top: 4px;
    right: -4px; */
}

.ion-margin {
  margin: 25px 0 5px 0 !important
}

.gray-border {
  font-family: Nunito Sans;
  border-top:solid 1px rgb(212, 210, 210);
  border-bottom:solid 1px rgb(212, 210, 210);
  padding: 10px 0;
  margin-right: 3px;
}

.gray-border-bottom {
  border-bottom:solid 1px rgb(212, 210, 210);
  padding-bottom: 15px;
}

.item {
  border-bottom:solid 1px rgb(212, 210, 210);
  padding: 15px 0px;
}

.item-text1 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 4px 2px;
}

.item-text2 {
font-size: 14px;
font-weight: 500;
line-height: 19px;
color: #00786D !important;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
margin: 4px 2px;
}

.item-text3 {
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
color: #99A4AB !important;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
margin: 4px 2px;
}
  .card-title {
    font-family: Nunito Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: unset;
    position: absolute;
    font-weight: bold;
    top:5px;
  }
  

.list-thumbnail.home{
  /* width: 20%;
  height: 20%; */
  height: 70px !important;
  width: 70px !important;
  background-color: #009688;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-top: 25px;
  margin-bottom: 10px !important;
  border-radius: 5px;
}

.dki-margin {
  margin-left: 12px;
}

.gray-color {
  color: #99A4AB !important;
}

.black-color {
  color: #000;
}

.my-custom-class {
  position: absolute;
  border-radius: 16px 16px 0px 0px;
  height: 400px !important;
  top: calc(100% - (400px));
  overflow: hidden !important;
}

.display-block { 
  display: block !important;
  text-align: center;
  margin: 10px auto;
}

.margin-5 {
  margin: 10px 2px 10px 2px;
}

.btn-modal {
  --background: #009688 !important;
  padding: 2px 20px;
  margin-top: 15px;
}

ion-searchbar ion-icon {
  right: -46px !important;
  left: auto !important;
}

.test
{
  position: absolute;
    right: 14px;
    z-index: 1;
    background-color: #009688;
    height: 32px;
    color: white;
    border-radius: 6px;

}

.button-native {
  padding-left: unset;
  padding-right: unset;
  padding-inline-start: var(--padding-start);
  padding-inline-end: var(--padding-end);
  border: -11px;
  border-radius: 4px !important;
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

.new-background-color{
  --background: #009688;;
}

.cust-01-page{
  background: rgba(0, 0, 0, 0.67);
  display: none;
  z-index: 9 !important;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

ion-slides {
  height: 100%;
}

.bg-1 {
  background-size: cover;
}
.bg-2 {
  background-size: cover;
}
.bg-3 {
  background-size: cover;
}

.category-name {
   position: absolute;
     width: 100%;
     text-align: center;
     min-height: 35px;
      bottom: 0px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      background: rgba(1, 28, 44, 0.5);
      border-radius: 0px 0px 4px 4px;
      display: flex;
      align-items: center;
      padding-left: 1rem;
  
}

.popup {
  --overflow: hidden !important;
}


.home-search-modal {
  margin: 10px;
  border-radius: 6px;
}
.ionlabel {
  width: 100%;
  text-align: left;
}

.button1 {
  position: fixed;
    bottom: 7%;
    width: 100%;
}
.carousal
{
  display:flex
}
.scrollmenu
{
  display: flex;
    overflow-x: scroll!important;
    overflow-y: hidden;
}
.myCard {
  --ion-display:flex;
  --ion-overflow-x:scroll;
}

@media only screen and (min-width: 415px)and (max-width: 600px) {
  .city-location {
    display: flex;
    justify-content:center;
    margin-left: 25px;
  }
  .list-items {
    font-family: Nunito Sans;
    padding-left: 3px;
  }
  .gray-border {
    margin-left: 7px;
  }
   .searchbarlist {
    margin: 12px;
  }
  /* .search {
    width: 96vw;
  } */
  .scrollmenu
  {
    margin-left: 12px;
  }
}

@media only screen and (min-width: 768px)and (max-width: 1024px) {
  .my-custom-class {
    position: absolute;
    --border-radius: 16px 16px 0px 0px;
    --width: 100%;
    --height: 400px !important;
    top: calc(100% - (400px));
  }
  /* .title-2 {
    margin-left: 17px;
  } */
  .home-search-modal{
    --ion-background-color:#09312d;
    --height:90px;
    --width:100%;
    --border-radius: 10px;
    opacity: 1;
  }
  .green-box {
    border-bottom-left-radius: 70% 80px;
    border-bottom-right-radius:70% 80px;
  }
  .city-location {
    display: flex;
    justify-content: space-between;
    margin: 3px 0px;
  }
  .gray-border {
    margin-left: 30px;
  }
  .searchbarlist {
    margin: 19px;
  }
  .search {
    width: 94%;
  }
  .scrollmenu
  {
    margin: 20px 0px 10px 30px ;
      overflow-x: hidden !important;
  }
  .product-list {
    width:70px;
    height:70px;
  }
  .list-items {
    padding-left: 13px;
  }
  .gray-border {
    padding-left: 14px;
  }
}
.not-found {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  color: #011C2C;
  text-align: center;
  margin: 0px 83px;
  }

.loadMore {
  text-align: center;
}

.reset-button {
  font-family: Nunito Sans;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  line-height: 19px;
  color: #03AD87;
  position: absolute;
  right: 16px;
  padding-top: 4px;
}
.reset-padding {
  padding-right: 50px !important;
}
.searchbar-input.sc-ion-searchbar-ios {
  background-color: #fff;
}

.txt-uppercase {
  text-transform: uppercase;
  overflow: hidden;
  line-height: 25px;
}

.city-align {
  text-align: left !important;
  padding-left: 20px !important;
}

img{
    width:100%;
    height:100%;
}

#myCard{
  position:relative;
  border-radius: 15px;
  height: 140px;
  width: 100px; 
  margin: 3px;
}

#myCard1{
  position:relative;
  border-radius: 15px;
  height: 140px;
  width: 100px; 
  margin: 3px;
 
}

#myCard2{
  position:relative;
  border-radius: 15px;
  height: 140px;
  width: 100px; 
  margin: 3px; 
}

.title-1 {
  font-family: Nunito Sans;
  color: #fff;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  margin-left: 13px;
  font-weight: bold;
  display: block;
}

.btn-ganti {
  font-family: Nunito Sans;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 16px;
letter-spacing: 0px;
text-align: center;
color: #fff;
border-color: #fff !important;
--border-color: ('#fff');
--border-radius: 0;
width: 62px !important;
height: 25px !important;
border-radius: 0px 0px !important;
text-transform: none;
}

.search-icon {
fill: #fff;
padding-top: 4px;
}

.ion-margin {
margin: 25px 0 5px 0 !important
}

.gray-border {
font-family: Nunito Sans;
border-top:solid 1px rgb(212, 210, 210);
border-bottom:solid 1px rgb(212, 210, 210);
padding: 10px 0;
margin-right: 3px;
}

.gray-border-bottom {
border-bottom:solid 1px rgb(212, 210, 210);
padding-bottom: 8px;
}

.item {
border-bottom:solid 1px rgb(212, 210, 210);
padding: 15px 0px;
}

.item-text1 {
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
white-space: break-spaces;
text-overflow: ellipsis;
margin: 4px 2px;
font-family: Roboto;

/* font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
color: #99A4AB !important;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
margin: 4px 2px; */
}

.item-text4 {
  font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
white-space: break-spaces;
text-overflow: ellipsis;
margin: 4px 2px;
font-family: Roboto;
color: #99A4AB !important;
}

.item-text2 {
font-size: 14px;
font-weight: 500;
line-height: 19px;
color: #00786D !important;
white-space: break-spaces;
overflow: hidden;
text-overflow: ellipsis;
margin: 4px 2px;
}

.item-text3 {
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
color: #99A4AB !important;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
margin: 4px 2px;
}

.card-title {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: unset;
  position: absolute;
  font-weight: bold;
  top:5px;
}

.list-thumbnail {
height: 70px !important;
width: 70px !important;
}

.dki-margin {
margin-left: 12px;
}

.gray-color {
color: #99A4AB !important;
}

.black-color {
color: #000;
}

.my-custom-class {
  position: absolute;
  border-radius: 16px 16px 0px 0px;
  height: 400px !important;
  top: calc(100% - (400px));
  overflow: hidden !important;
}

.display-block { 
display: block !important;
text-align: center;
padding: 7px;
}

.margin-5 {
margin: 10px 2px 10px 2px;
}

.btn-modal {
--background: #009688 !important;
padding: 2px 20px;
margin-top: 15px;
}

ion-searchbar ion-icon {
right: -46px !important;
left: auto !important;
}
.button-native {
padding-left: unset;
padding-right: unset;
padding-inline-start: var(--padding-start);
padding-inline-end: var(--padding-end);
border: -11px;
border-radius: 4px !important;
}

.test {
 position: absolute;
  right: 0px;
  z-index: 1;
  background-color: #009688;
  height: 32px;
  color: white;
  border-radius: 6px;

}
.popup {
  --overflow: hidden !important;
  padding: 0;
  margin: 0;
}
.home-search-modal {
  --ion-background-color:#09312d;
}
ion-searchbar {
  --ion-background-color:white;
}
.home-search-modal {
  margin: 0px;
}

.mdlcls1
{
position: relative;
  top: 13px;
  right: -17px;
  font-size: 24px;
  height: 30px;
  margin-bottom: 10px;
}

.searchbar {
  display: flex;
  justify-content: space-between;

}
.searchbarlist {
  margin-top: 14px;
  margin-right: 10px;
}
.mdlcls {
    position: relative;
      top: 22px;
      right: -5px;
      font-size: 23px;
      color: white;  
}

.search {
  color: #99A4AB;
  text-align: left;
  padding: 0;
  position: absolute;
  left: 3%;
  width: 94vw;
  height: 45px;
  border-radius: 6px;
  background-color: #fff;
  padding-right: 10px;
}

#overlay {
position: fixed;
display: none;
width: 100%;
height: 100%;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0,0,0,0.5);
z-index: 2;
cursor: pointer;
}

.new-background-color{
--background: #009688;;
}

#nvgbutton {
text-transform: capitalize;
height: 58px;
position: relative;
top: 20px;
font-weight: bold !important;
}

.scroll-y {
  touch-action: pan-y;
  overscroll-behavior-y: contain;
}

/* .mdlcls1
{
position: relative;
  top: 13px;
  right: -17px;
  font-size: 24px;
  height: 30px;
} */

.scrollmenu
{
  display: flex;
    overflow-x: scroll!important;
    overflow-y: hidden;
}

.active-card{
  position: relative;
  border: 3px solid #F7B500;
  visibility: visible;
}

.active-card .close-icon-btn{
  font-size: 12px;
}

.card-close-btn-outer{
  display: none;
}

.active-card .card-close-btn-outer{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F7B500;
  color: white;
  border-radius: 50%;
  padding: 3px;
  right: 7px;
  top: 7px;
  z-index: 998;
}
@media only screen and (min-width: 1025px)and (max-width: 2024px) {
  .my-custom-class {
    position: absolute;
    --border-radius: 16px 16px 0px 0px;
    --width: 100%;
    --height: 400px !important;
    top: calc(100% - (400px));
  }
  .home-search-modal {
    --height:90px;
    --width:100%;
    --border-radius: 10px;
  }
  .green-box {
    border-bottom-left-radius: 70% 80px;
    border-bottom-right-radius:70% 80px;
  }
  .city-location {
    display: flex;
    justify-content: space-between;
    margin: 3px 0px;
  }
  
  .searchbarlist {
    margin: 19px;
  }
  .search {
    width: 94%;
  }
  .scrollmenu
  {
    margin: 20px 0px 10px 18px ;
      overflow-x: hidden !important;
  }
  .product-list {
    width:70px;
    height:70px;
  }
  .list-items {
    padding-left: 13px;
  }

}



