.my-custom-class {
    --spinner-color: #2f9688;
    /* height: 100%; */
  }

  .loader-style {
    height: 100vh !important;
    --spinner-color: #2f9688;

  }

